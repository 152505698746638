import * as React from 'react'
import Layout from "../components/layout/layout";

const TermsOfServicePage = () => {
    return (
        <Layout pageTitle="Terms of Service" pageKeywords="terms of service belthosting" pageDescription="Terms of Service BeltHosting">
            <div className="row">
                <div className="col-md-2"/>
                <div className="col-md-8">
                    <div className="page">
                        <div className="title">
                            Terms of Service
                            <div className="last_updated">
                                last updated: 03.02.2022
                            </div>
                        </div>
                        <div className="content">
                            <p>What's not allowed?</p>
                            <ul>
                                <li>Running any type of IRC bouncers, as we are not Shell Provider, for that you need to
                                    use xShellz's plans, which you can find here.
                                </li>
                                <li>Any type of Adult/Porn Contest</li>
                                <li>Warez</li>
                                <li>Proxy</li>
                                <li>No DDoS</li>
                                <li>Any malware script/or cpu/memory over usage scripts</li>
                            </ul>
                            <p>Payments</p>
                            <h7>WE ACCEPT FOLLOWING PAYMENT GATEWAYS:</h7>
                            <ul>
                                <li>PayPal *</li>
                                <li>Credit Cards *</li>
                            </ul>
                            * Our payment gateway is 2checkout.<br/><br/>
                            <p>Refund Policy</p>
                            <ul>
                                <li>You have a strong right to claim refund for any of our products!</li>
                                <li>You may not claim refund, if you are using our product more then 15 days!</li>
                                <li>You may not claim refund, if your service is suspended because of illegal activity
                                    or not respecting our TOS.
                                </li>
                                <li>If you have a positive credit balance, you can use it for our other services only if
                                    you are active client with at least one active service/product
                                </li>
                                <li>Positive credit balance will be deducted from your account in case of inactivity of
                                    more than 5 months. After 5 months the positive amount will be deducted from your
                                    credit balance.
                                </li>
                                <li>You can fill a refund form and cancel your service only if you have a valid reason
                                    why are you leaving us. (To protect our hosting from abusers, and make your website
                                    more secured and faster).
                                </li>
                            </ul>
                            <p>Late Fees</p>
                            <ul>
                                <li>If you forget to pay on time, you may be charged more.</li>
                                <li>We apply late fees (10%) on your service, in order to protect and free unused
                                    service
                                    and IP's.
                                </li>
                                <li>To avoid late fees, and missed invoices, you may set recurring billing or add money
                                    to
                                    your credit balance account
                                </li>
                            </ul>
                            <p>SLA Conditions</p>
                            <ul>
                                <li>If your service drops below 99.9% uptime in any given month you will be credited
                                    with one free month. This must be claimed within 7 days of our monitoring dropping
                                    below this level.
                                </li>
                                <li>Any planned maintenance work is except from this guarantee and will be ignored.
                                    Dedicated servers and reseller discounted VPS currently have no SLA.
                                </li>
                                <li>Uptime level is calculated by our status page and monitors only.</li>
                                <li>Only one valid claim can be made per calendar month.</li>
                                <li>Downtime occurring due to natural disasters or DDoS attacks against the network do
                                    not count towards the SLA calculations.
                                </li>
                                <li>These Terms of Service and Acceptable Use Policies ("Terms and Policies") are an
                                    essential part of your relationship with BeltHosting. By using your BeltHosting
                                    account, you agree to all of the Terms and Policies set out in this document.
                                </li>
                            </ul>
                            <p>Web Hosting</p>
                            <ul>
                                <li>The Service is provided to authorised persons or organisations (referred to in this
                                    document as "Subscriber" or "you"). Any use of the service is subject to any
                                    restrictions listed below. By using the Service, you agree to be bound by all of
                                    theses Terms and Policies. If you do not agree to be bound by these Terms and
                                    Policies, you must cancel your account immediately and may not thereafter use or
                                    attempt to use the Service.
                                </li>
                                <li>Coverage - If you are an individual Subscriber, these Terms and Policies apply to
                                    all persons who gain access through your account. If you are a commercial
                                    Subscriber, these Terms and Policies apply to all your employees, agents and/or
                                    customers. In either instance, a violation of these Terms and Policies by anyone
                                    using your account will be treated as a violation by you.
                                </li>
                                <li>Prices and Charges - BeltHosting provides the use of cPanel/WHM Inc.</li>
                                <li>Term - The Service is provided to you for as long as you wish to use it, however we
                                    withhold the right to terminate the service if you use it in a way that violates the
                                    terms of use.
                                </li>
                                <li>Backups - It is the customers responsibility to backup any files/data that are
                                    stored in the hosting account. BeltHosting can not be held responsible for any loss
                                    of files/data stored in the customers account. BeltHosting have 1 backup-transfer
                                    per day and holds them for 30 days, these backups are strictly for our own use
                                    in-case of a server failure. However you can also login to the backup server to
                                    restore files. This is not meant as a replacement for keeping your own backups.
                                </li>
                            </ul>
                            <p>Privacy Policy (Legal Agreement)</p>
                            <ul>
                                <li>BeltHosting is committed to ensuring that your privacy is protected. Any personal
                                    information you do provide to us will be kept for as long as necessary to achieve
                                    the purpose you submitted it for.
                                </li>
                                <li>We are committed to ensuring that your privacy is protected.</li>
                                <li>We do not automatically record personal information.</li>
                                <li>We do not link information that is recorded automatically with personal information
                                    about specific individuals.
                                </li>
                                <li>We automatically record some non-personal information.</li>
                                <li>We do not attempt to identify users or their browsing activities unless they choose
                                    to give us personal information.
                                </li>
                                <li>The BeltHosting website uses Google Analytics to help analyze how people use our
                                    sites. This application uses 'cookies'.
                                </li>
                                <li>The information generated by the cookie about your use of the website (including
                                    your computer's internet address or IP address) is transmitted to and stored by
                                    Google.
                                </li>
                                <li>We will not use the web analytics tool to track or to collect any personally
                                    identifiable information of visitors to our sites, such as user IDs and passwords.
                                </li>
                                <li>You have the ability to accept or decline cookies by modifying the settings on your
                                    browser. Disabling cookies will affect using the BeltHosting website.
                                </li>
                                <li>We collect personal information when you: Register, login, and visit any of our
                                    pages.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-2"/>
            </div>
        </Layout>
    )
}
export default TermsOfServicePage
